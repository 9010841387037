import Dashboard from '../views/dashboard/index.vue'
import Login from '../views/Login.vue'
import Main from '../views/Main.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { generateRoutes } from '@/common/utils/generateRoutes';

Vue.use(VueRouter)
const menuList = JSON.parse(window.sessionStorage.getItem("menuList"));
const routesApi = (menuList && generateRoutes(menuList)) || [];
const routes = [
  {
    path: '/', component: Main,
    redirect: '/dashboard',
    children: routesApi
  },
  { path: '/login', name: 'login', component: Login }
]

const router = new VueRouter({
  routes
})

export default router
