import { deleteRequest, downPostRequest, getRequest, postRequest, putRequest } from '@/common/config/request';

export const userListPaging = (data) =>{
    return postRequest("/auth/api/user/list-paging",data);
}

export const updateUser = (data) =>{
    return putRequest("/auth/api/user/updateUser",data);
}

export const createUser = (data) =>{
    return postRequest("/auth/api/user/createUser",data);
}

export const deleteUser = (data) =>{
    return deleteRequest("/auth/api/user/deleteUser",data);
}

export const unBindUserTenantRole = (data) =>{
    return putRequest("/auth/api/user/unbindUserTenant",data);
}

export const bindUserRolePaging = (data) =>{
    return postRequest("/auth/api/role/user-role-list-paging",data);
}

export const updateUserRole = (data) =>{
    return putRequest("/auth/api/user/update-user-role",data);
}

export const getUserInfo = (data) =>{
    return getRequest("/auth/api/user/userInfo",data);
}

export const getUserxiangxiInfo = (data) =>{
    return getRequest("/auth/api/appWorkOrder/dayDataSum/"+data.id,data);
}

export const getUserxiangxidanshu = (data) =>{
    return postRequest("/auth/api/crmWorkOrder/history",data);
}

export const getUserjiedanrenshu = (data) =>{
    return postRequest("/auth/api/crmWorkOrder/jiedanyuan",data);
}