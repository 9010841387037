import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
export const generateRoutes = (data) => {
    let fmRoutes = [];
    data.forEach(item => {
        let {
            name,
            path,
            metaVO,
            hidden,
            isFrame,
            isCache,
            component,
            redirect,
            visible,
            status,
            children = []
        } = item;
        // if (isFrame === 1) { // 如果是内嵌网页，则不添加进路由
        //     return;
        // }
        let fmRouter = {
            path: path.startsWith('/') ? path.substring(path.indexOf('/') + 1) : path,
            name: name,
            redirect: redirect,
            meta: metaVO,
            hidden:hidden,
            //hidden: visible === "0", // 后台拉取如果有hidden为 0 的就是不显示
            //isCache: !isCache,
            component: (resolve) => {
                component = component.startsWith('/') ? component.substring(component.indexOf('/') + 1) : component;
                if (component.substring(component.lastIndexOf('/') + 1).endsWith('.vue')) {
                    if (component.endsWith('index.vue')) {
                        require(['../../views/' + component.replace(/\.vue$/, '')], resolve);
                    } else {
                        require(['../../views/' + component], resolve);
                    }
                } else if (component.endsWith('/index')) {
                    require(['../../views/' + component], resolve);
                } else {
                    require(['../../views/' + component + '.vue'], resolve);
                }
            },
            children: children && children.length > 0 ? generateRoutes(children) : children,
        };
        fmRoutes.push(fmRouter);
    });
    return fmRoutes;
}

export const resetRouter = (router, data) => {
    const routes = [
        {
            path: '/', component: () => import('../../views/Main.vue'),
            redirect: '/dashboard',
            children: data
        },
        { path: '/login', name: 'login', component: () => import('../../views/Login.vue') },
    ]
    router.matcher = new VueRouter({
        mode: 'history',
        routes: []
    }).matcher
    router.addRoutes(routes)
}