//登录

import { deleteRequest, downPostRequest, getRequest, postRequest, putRequest } from '@/common/config/request';

export const login = (data) =>{
    return postRequest("/auth/api/login/login",data);
}

export const logout = (data) =>{
    return getRequest("/auth/api/login/logout",data);
}

//获取路由
export const getRouteList = (data) =>{
    return getRequest("/auth/api/menu/tree",data);
}