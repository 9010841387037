import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { deleteRequest, downPostRequest, getRequest, postRequest, putRequest } from '@/common/config/request'

import router from './router'
import store from './store'
import Time from '@/common/utils/formatTime'

//需要挂载到Vue原型上
Vue.prototype.getRequest = getRequest;
Vue.prototype.postRequest = postRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.downPostRequest = downPostRequest;
Vue.prototype.$Time = Time;

Vue.use(ElementUI);

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem('token');
  if (!token && to.name !== 'login') {
    next({ name: 'login' });
  } else if (token && to.name === 'login') {
    next({ name: 'dashboard' });
  } else {
    next();
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
