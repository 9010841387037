import {generateRoutes} from '@/common/utils/generateRoutes'

export default {
    state: {
        isCollapse: false,//控制菜单的展开或收起
        menuList: [],
        routes: [],
        userInfo:{},
        isSelectedTenant: {},
        isSelectedMenu: {},
    },
    mutations: {
        //修改菜单展开或收起方法
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse;
        },
        setMenuList(state, menuList) {
            state.menuList = menuList;
            window.sessionStorage.setItem("menuList", JSON.stringify(menuList));
        },
        setRouterList(state) {
            const menuList = JSON.parse(window.sessionStorage.getItem("menuList"));
            generateRoutes(menuList);
        },
        setUserInfo(state,userInfo){
            state.userInfo = userInfo;
        },
        selectedTenant(state,selectedTenant){
            state.isSelectedTenant = selectedTenant;
        },
        selectedMenu(state,selectedMenu){
            state.isSelectedMenu = selectedMenu;
        },
    }
}