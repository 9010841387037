<template>
  <div class="sidebar">
    <el-menu
      default-active="1"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <h3>{{ isCollapse ? "后台" : systemName }}</h3>
      <common-menu-item-aside :menuItemData="menuData"></common-menu-item-aside>
    </el-menu>
  </div>
</template>

<script>
import {SYSTEM_NAME} from"@/common/constant/Const";
import CommonMenuItemAside from "./CommonMenuItemAside.vue";
export default {
  name: "CommonMenuAside",
  components: {
    CommonMenuItemAside,
  },
  data() {
    return {
      systemName: SYSTEM_NAME,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  computed: {
    //没有子菜单
    noChildren() {
      return this.menuData.filter((item) => !item.children);
    },
    //有子菜单
    hasChildren() {
      return this.menuData.filter((item) => item.children);
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
    menuData() {
      return this.$store.state.tab.menuList &&
        this.$store.state.tab.menuList.length > 0
        ? this.$store.state.tab.menuList
        : JSON.parse(window.sessionStorage.getItem("menuList"));
    },
  },
};
</script>

<style lang="less" scoped>
// .el-menu-vertical-demo:not(.el-menu--collapse) {
//   width: 200px;
//   min-height: 400px;
// }
.el-menu {
  height: 100vh;
  border-right: none;
  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
  }
}
</style>
