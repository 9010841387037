<template>
  <div>
    <!-- 第一层 -->
    <template v-for="item in menuItemData">
      <template v-if="!item.hidden">
        <!-- 如果第一层有子菜单，则继续循环 -->
        <template v-if="item.children && item.children.length > 0">
          <el-submenu :index="item.index" :key="item.index">
            <template slot="title">
              <i :class="`el-icon-${item.metaVO.icon}`"></i>
              <span slot="title">{{
      isCollapse ? "" : item.metaVO.title
    }}</span>
            </template>
            <!-- 第二层 -->
            <common-menu-item-aside :menuItemData="item.children"></common-menu-item-aside>
          </el-submenu>
        </template>
        <!-- 如果第一层没有子菜单 -->
        <template v-else>
          <el-menu-item @click="clickMenu(item)" :index="item.index" :key="item.index">
            <i :class="`el-icon-${item.metaVO.icon}`"></i>
            <span slot="title">{{ item.metaVO.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import CommonMenuItemAside from "./CommonMenuItemAside.vue";
export default {
  name: "CommonMenuItemAside",
  components: {
    CommonMenuItemAside,
  },
  props: {
    menuItemData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickMenu(item) {
      if (item.redirect) {
        if (this.checkRouter({ path: item.redirect })) {
          if (item.query && item.query.length > 0) {
            this.$router.push(item.query + item.redirect);
          } else {
            this.$router.push(item.redirect);
          }
        }
      } else {
        if (this.checkRouter({ path: item.path })) {
          console.log("tiaozhuan", item.path);
          if (item.query && item.query.length > 0) {
            this.$router.push(item.query + item.path);
          } else {
            this.$router.push(item.path);
          }
        }
      }
    },
    // 当前页面路由与跳转路由不一致时才允许跳转
    checkRouter(data) {
      console.log(this.$route.path, data);
      return (
        // 当前路由     要跳转的路由
        this.$route.path !== data.path &&
        // 有重定向（redirect）的时候，当前路由不等于被跳转的路由（最终url）并且
        !(this.$route.path === "dashboard" && data.path === "/") &&
        !(this.$route.path === "/tenant/tenant/tenantList" && data.path === "/tenant/tenantList") &&
        !(this.$route.path === "/user/user/userList" && data.path === "/user/userList") &&
        !(this.$route.path === "/role/role/roleList" && data.path === "/role/roleList") &&
        !(this.$route.path === "/auth/auth/authList" && data.path === "/auth/authList") &&
        !(this.$route.path === "/log/log/logList" && data.path === "/log/logList") &&
        !(this.$route.path === "/menu/menu/menuList" && data.path === "/menu/menuList") &&
        true
      );
    },
  },
  computed: {
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
};
</script>
