// 租户管理

import { deleteRequest, downPostRequest, getRequest, postRequest, putRequest } from '@/common/config/request';

export const tenantListPagingLogin = (data) =>{
    return postRequest("/auth/api/tenant/list-paging-login",data);
}

export const tenantListPaging = (data) =>{
    return postRequest("/auth/api/tenant/list-paging",data);
}

export const createTenant = (data) =>{
    return postRequest("/auth/api/tenant/createTenant",data);
}

export const updateTenant = (data) =>{
    return putRequest("/auth/api/tenant/updateTenant",data);
}

export const deleteTenant = (data) =>{
    return deleteRequest("/auth/api/tenant/deleteTenant",data);
}

export const unBindUserTenant = (data) =>{
    return putRequest("/auth/api/user/unbindUserTenant",data);
}