import { Message } from 'element-ui';
import axios from "axios";
import router from '@/router';
import {AUTHORIZATION,BEARER} from"@/common/constant/Const";

//请求拦截器
axios.interceptors.request.use(function (config) {
    //请求前做的事
    const token = window.sessionStorage.getItem("token");
    if (token) {
        config.headers[AUTHORIZATION] = BEARER + token;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
})

//响应拦截器
axios.interceptors.response.use(function (resp) {
    //响应数据做的事
    if (resp.data.msg) {
        if(resp.data.code === '0'){
            Message.success({ message: resp.data.msg });
        }else{
            Message.error({ message: resp.data.msg })
        }
    }
    return resp.data;
}, function (error) {
    if (error.response.code == 504 || error.response.code == 404) {
        Message.error({ message: '服务起找不到...' });
    } else if (error.response.code == 403) {
        Message.error({ message: '权限不足，请联系管理员！' });
    } else if (error.response.code == 401) {
        Message.error({ message: '尚未登录，请登录！' });
        router.replace('/login');
    } else {
        if (error.response.data.msg) {
            Message.error({ message: error.response.data.msg });
        } else {
            Message.error({ message: '未知错误！' });
        }
    }
    return;
})

let base = 'https://sulitong.weizhongcloud.com';
// let base = 'http://192.168.1.30:8085';
export const getRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params
    })
}

export const postRequest = (url, params,headers) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        // headers: { "Content-Type": "application/json" }
        headers:headers
    })
}
export const deleteRequest = (url, params) => {
    return axios({
        method: 'delete',
        url: `${base}${url}`,
        data: params
    })
}

export const putRequest = (url, params) => {
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params
    })
}

export const downPostRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        responseType: 'blob',
        // headers: {
        //     "Content-Type": "application/json;charset=UTF-8",
        // },
    })
}
