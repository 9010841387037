<template>
  <div class="login-main">
    <div ref="container" class="container panel-active">
      <!-- 登录 -->
      <div class="formbox login">
        <el-form ref="loginForm" :model="loginForm" v-loading="loading" element-loading-text="正在登录..."
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" :rules="rules"
          class="form">
          <h2 class="title">登 录</h2>
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" placeholder="请输入密码" show-password></el-input>
          </el-form-item>
          <!-- <el-form-item prop="tenantId">
            <el-select v-model="loginForm.tenantId" filterable placeholder="请选择租户">
              <el-option v-for="item in pagingData.totalList" :key="item.id" :label="item.tenantName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item style="text-align: right;">
            <!-- <el-link :underline="false">忘记密码？</el-link> -->
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="loginSubmit">登 录</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 注册 -->
      <div class="formbox register">
        <el-form ref="registerForm" :model="registerForm" class="form">
          <h2 class="title">注 册</h2>
          <el-form-item prop="username">
            <el-input v-model="registerForm.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="registerForm.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary">注 册</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 浮层 -->
      <div class="overlay-box">
        <div class="overlay">
          <div class="panel over-left">
            <div>

            </div>
            <div class="right-button">
              <!-- <el-link :underline="false" @click="signUp">无账号？前往注册</el-link> -->
            </div>

          </div>
          <div class="panel over-right">
            <el-link :underline="false" @click="signIn">已有账号？立即登录</el-link>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  tenantListPagingLogin,
} from "@/common/api/tenant/index";

import {
  login,
  getRouteList,
} from "@/common/api/login/index";

import { generateRoutes, resetRouter } from "@/common/utils/generateRoutes";
export default {
  components: {},
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        tenantId: '-1',
      },
      registerForm: {
        username: '',
        password: '',
      },
      paging: {
        currentPage: 1,
        pageSize: 10,
        searchVal: null,
      },
      pagingData: {
        currentPage: "",
        pageSize: "",
        total: "",
        totalPage: "",
        totalList: [],
      },
      loading: false,
      rules: {
        username: [
          { required: true, trigger: "blur", message: "请输入用户名" },
        ],
        password: [{ required: true, trigger: "blur", message: "请输入密码" }],
        // tenantId: [{ required: true, trigger: "blur", message: "请输入租户" }],
      },
      routeParams: {
        currentPage: 1,
        pageSize: 100,
        searchVal: null,
        userId: null,
      },
    }
  },
  mounted() {
    this.initTenant();
  },
  methods: {
    signUp() {
      this.$refs.container.classList.remove('panel-active');
    },
    signIn() {
      this.$refs.container.classList.add('panel-active');
    },
    initTenant() {
      tenantListPagingLogin(this.paging).then((resp) => {
        this.pagingData.currentPage = resp.data.currentPage;
        this.pagingData.pageSize = resp.data.pageSize;
        this.pagingData.total = resp.data.total;
        this.pagingData.totalPage = resp.data.totalPage;
        this.pagingData.totalList = resp.data.totalList;
      });
    },
    loginSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          login(this.loginForm).then((resp) => {
            if (resp.data) {
              const token = resp.data.token;
              window.sessionStorage.setItem("token", token);
              this.loading = false;
              getRouteList().then((result) => {
                if (result.data) {
                  this.menuData = result.data;
                  this.$store.commit("setMenuList", this.menuData);
                  this.$store.commit("setRouterList");

                  const data = resp.data;
                  const userInfo = {
                    userId: data.userId,
                    userName: data.userName,
                    name: data.name,
                    userType: data.userType,
                    tenantId: '-1',
                    tenantName: data.tenantName,
                    loginTime: data.loginTime,
                    loginPlace: data.loginPlace,
                    display: data.display,
                  };
                  window.sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify(userInfo)
                  );
                  this.$store.commit("setUserInfo", userInfo);
                  const respRoutes = generateRoutes(this.menuData);
                  resetRouter(this.$router, respRoutes);
                }
              });
            } else {
              this.loading = false;
            }
          });
        }
      });
    }
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.login-main {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url('@/assets/images/login-background.jpg');
  background-size: cover;
}

.container {
  width: 100%;
  max-width: 758px;
  height: 420px;
  position: relative;
  margin: auto;
}

.formbox {
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.6s ease-in-out;
}

.login {
  left: 0;
  opacity: 0;
  width: 50%;
  z-index: 1;
}

.register {
  left: 0;
  width: 50%;
  z-index: 2;
}

.form {
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 3rem;
  height: 100%;
  text-align: center;
}

.title {
  font-weight: 300;
  margin: 0;
  margin-bottom: 1.25rem;
}

.overlay-box {
  height: 100%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: transform 0.6s ease-in-out;
  width: 50%;
  z-index: 100;
}

.overlay {
  background-color: rgba(255, 255, 255, 0.25);
  height: 100%;
  left: -100%;
  position: relative;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  width: 200%;
}

.panel {
  width: 50%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.over-left {
  transform: translateX(-20%);
}

.over-right {
  right: 0;
  transform: translateX(0);
}



.panel-active .register {
  transform: translateX(100%);
}

.panel-active .login {
  opacity: 1;
  transform: translateX(100%);
  z-index: 3;
}

.panel-active .overlay-box {
  transform: translateX(-100%);
}

.panel-active .overlay {
  transform: translateX(50%);
}

.panel-active .over-left {
  transform: translateX(0);
}

.panel-active .over-right {
  transform: translateX(20%);
}

.el-form-item,
.el-select {
  width: 100%;
}

.right-button {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-15%, -40%);
}
</style>
