<template>
  <div class="footer-main">
    <div class="left-footer-content">
      <span class="text">
        © {{ edition }} 
        <!-- &nbsp;&nbsp; {{ systemName }} 
        &nbsp;&nbsp; {{ company }} 
        &nbsp;&nbsp; {{ phone }} -->
        &nbsp;&nbsp;&nbsp;&nbsp;备案号：{{ recordNumber }}</span>
    </div>
  </div>
</template>

<script>
import { EDITION, SYSTEM_NAME, COMPANY, PHONE, RECORD_NUMBER } from "@/common/constant/Const";
export default {
  name: "CommonFooter",
  components: {},
  data() {
    return {
      edition: EDITION,
      systemName: SYSTEM_NAME,
      company: COMPANY,
      phone: PHONE,
      recordNumber: RECORD_NUMBER,
    };
  },
  methods: {},
  computed: {},
};
</script>

<style lang="less" scoped>
.footer-main {
  background-color: #333;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-footer-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  color: #fff;
  font-size: 14px;
}
</style>
