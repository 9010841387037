<template>
  <div id="dashboard-main">
    <el-row>
      <el-col :span="8" style="padding-right: 10px">
        <el-card class="box-card">
          <div class="user">
            <img src="../../assets/images/system/default-profile-picture.jpg" alt="" />
            <div class="userInfo">
              <p class="name">{{ userInfo.name }}</p>
              <p class="access">{{ userInfo.tenantName }}</p>
            </div>
          </div>
          <div class="loginInfo">
            <p>
              登录时间：<span>{{ userInfo.loginTime }}</span>
            </p>
            <p>
              登录地点：<span>{{ userInfo.loginPlace }}</span>
            </p>
          </div>
        </el-card>
        <el-card style="margin-top: 20px; max-height: 55vh">
          <div class="table-container">
            <el-table :data="rowTableData" style="width: 100%">
              <el-table-column v-for="(val, key) in rowTableLabel" :prop="key" :label="val" :key="key" />
            </el-table>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16" style="padding-left: 10px">
        <el-card style="height: 460px;">
          <div ref="domain-container" >
            <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels range-separator="至"
              start-placeholder="开始日期" value-format="timestamp" @change="datepicker" end-placeholder="结束日期"
              :picker-options="pickerOptions">
            </el-date-picker>
            <div class="sumData" style="margin-top: 20px;">
              <el-card v-for="item in sumTableData" :key="item.name" :body-style="{ display: 'flex', padding: 0 }">
                <i class="icon" :class="`el-icon-${item.icon}`" :style="{ background: item.color }"></i>
                <div class="detail">
                  <p class="count">{{ item.value }}</p>
                  <p class="desc">{{ item.name }}</p>
                </div>
              </el-card>
            </div>
            <div>
              <el-table height="250" ref="multipleTable" :cell-style="{'text-align':'center','font-size':'14px'}"
                  :header-cell-style="{'text-align':'center'}" tooltip-effect="dark"
                  :data="pagingData.totalList" style="width: 100%">
                  <el-table-column prop="title" label="标题" :show-overflow-tooltip="true" width="180"></el-table-column>
                  <el-table-column prop="receiveName" label="接收人" width="80"></el-table-column>
                  <el-table-column prop="receivePhone" label="手机号" width="100"></el-table-column>
                  <el-table-column prop="createTime" label="下单时间" width="180">
                      <template slot-scope="scope">
                          <div>{{$Time.formatLongToStr(scope.row.createTime)}}</div>
                      </template>
                  </el-table-column>
                  <el-table-column prop="status" label="状态" width="220">
                      <template slot-scope="scope">
                          <el-tag v-if="scope.row.status==1" type="success">待支付</el-tag>
                          <el-tag v-if="scope.row.status==2" type="success">支付成功</el-tag>
                          <el-tag v-if="scope.row.status==3" type="success">已取消</el-tag>
                          <el-tag v-if="scope.row.status==4" type="success">待安装</el-tag>
                          <el-tag v-if="scope.row.status==5" type="success">已完成</el-tag>
                          <el-tag v-if="scope.row.status==6" type="danger">退单原因：{{scope.row.refundReason}}</el-tag>
                          <!-- <el-tag v-if="scope.row.status==6" type="info"></el-tag> -->
                      </template>
                  </el-table-column>
                  <el-table-column prop="payMoney" label="金额" width="80"></el-table-column>
              </el-table>

            </div>
            <!-- 数据主要展示区域 -->
          </div>
        </el-card>
        <!-- <div class="graph">
          <el-card style="height:260px;"></el-card>
          <el-card style="height:260px;"></el-card> -->
        <!-- <el-row>
            <el-col :span="12">
              <el-card></el-card>
            </el-col>
            <el-col :span="12">
              <el-card></el-card>
            </el-col>
          </el-row> -->
        <!-- </div> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    getUserxiangxidanshu,getUserjiedanrenshu
  } from "@/common/api/user/index";
  export default {
    data() {
      return {
        pagingData:[],
        wcdanshu: 0,
        zongdanshu: 0,
        jiedanyuan:0,
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value1: '',
        value2: '',
        userInfo: JSON.parse(window.sessionStorage.getItem("userInfo")),
        rowTableLabel: {
          name: "名称",
          count: "数量",
        },
        rowTableData: [
          // {
          //   name: "假数据1",
          //   count: 34,
          // },
          // {
          //   name: "假数据2",
          //   count: 35,
          // },
        ],

        sumTableData: [
          {
            name: "接单员人数",
            value: 0,
            icon: "user-solid",
            color: "#2ec7c9",
          },
          {
            name: "总接单数量",
            value: 0,
            icon: "s-order",
            color: "#ffb980",
          },
          {
            name: "完成单数量",
            value: 0,
            icon: "success",
            color: "#5ab1ef",
          },
        ],
      };
    },
    mounted() {
      this.datepicker();
    },
    methods: {
      datepicker(row) {
        var data = {
          "currentPage": 1,
          "endTime": this.value2[1],
          "pageSize": 10000,
          "startTime": this.value2[0],
          "status": 5,
        };
        getUserxiangxidanshu(data).then((resp) => {
          this.wcdanshu = resp.data.total;
          this.sumTableData[2].value = resp.data.total;
          this.pagingData = resp.data;
          console.log(resp.data.total);
        });
        var data1 = {
          "currentPage": 1,
          "endTime": this.value2[1],
          "pageSize": 10000,
          "startTime": this.value2[0],
        };
        getUserxiangxidanshu(data).then((resp) => {
          this.zongdanshu = resp.data.total;
          this.sumTableData[1].value = resp.data.total;
          console.log(resp.data.total);
        });
        var data2 = {
          "currentPage": 1,
          "pageSize": 10000,
        };
        console.log();
        getUserjiedanrenshu(data2).then((resp) => {
          this.jiedanyuan = resp.data.total;
          this.sumTableData[0].value = resp.data.total;
          // console.log(resp.data.total);
        });
      },

    },
  };
</script>

<style lang="less" scoped>
  .user {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;

    img {
      margin-right: 40px;
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    .userInfo {
      .name {
        font-size: 32px;
        margin-bottom: 10px;
      }

      .access {
        color: #999999;
      }
    }
  }

  .loginInfo {
    p {
      line-height: 28px;
      font-size: 14px;
      color: #999999;

      span {
        color: #666666;
        margin-left: 60px;
      }
    }
  }

  .table-container {
    max-height: 400px;
    overflow-y: auto;
  }

  .sumData {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .icon {
      width: 80px;
      height: 80px;
      font-size: 30px;
      color: #fff;
      text-align: center;
      line-height: 80px;
    }

    .detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 15px;

      .count {
        font-size: 30px;
        margin-bottom: 10px;
        line-height: 30px;
        height: 30px;
      }

      .desc {
        font-size: 14px;
        color: #999;
        text-align: center;
      }
    }

    .el-card {
      width: 32%;
      margin-bottom: 20px;
    }
  }

  .domain-container {
    height: 100%;
  }

  .graph {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .el-card {
      width: 48%;
    }
  }
</style>