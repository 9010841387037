export const formatLongToStr = (data) => {
    const date = new Date(data);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

export const dateTimeInMilliseconds = (dateTimeString) =>{
    var dateObj = new Date(dateTimeString);
    return dateObj.getTime();
}
export default {formatLongToStr,dateTimeInMilliseconds};